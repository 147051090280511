define("discourse/plugins/chat/discourse/services/chat", ["exports", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/user-presence", "discourse-common/lib/deprecated", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _tracking, _object, _computed, _runloop, _service, _ajax, _ajaxError, _userPresence, _deprecated, _later, _decorators, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHAT_ONLINE_OPTIONS = {
    userUnseenTime: 300000,
    // 5 minutes seconds with no interaction
    browserHiddenTime: 300000 // Or the browser has been in the background for 5 minutes
  };
  class Chat extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatNotificationManager", [_service.service]))();
    #chatNotificationManager = (() => (dt7948.i(this, "chatNotificationManager"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chatSubscriptionsManager", [_service.service]))();
    #chatSubscriptionsManager = (() => (dt7948.i(this, "chatSubscriptionsManager"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "chatDraftsManager", [_service.service]))();
    #chatDraftsManager = (() => (dt7948.i(this, "chatDraftsManager"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    cook = null;
    presenceChannel = null;
    sidebarActive = false;
    isNetworkUnreliable = false;
    static #_13 = (() => dt7948.g(this.prototype, "userCanChat", [(0, _computed.and)("currentUser.has_chat_enabled", "siteSettings.chat_enabled")]))();
    #userCanChat = (() => (dt7948.i(this, "userCanChat"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "_activeMessage", [_tracking.tracked], function () {
      return null;
    }))();
    #_activeMessage = (() => (dt7948.i(this, "_activeMessage"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "_activeChannel", [_tracking.tracked], function () {
      return null;
    }))();
    #_activeChannel = (() => (dt7948.i(this, "_activeChannel"), void 0))();
    init() {
      super.init(...arguments);
      if (this.userCanChat) {
        this.presenceChannel = this.presence.getChannel("/chat/online");
        (0, _userPresence.onPresenceChange)({
          callback: this.onPresenceChangeCallback,
          browserHiddenTime: 150000,
          userUnseenTime: 150000
        });
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.userCanChat) {
        this.chatSubscriptionsManager.stopChannelsSubscriptions();
        (0, _userPresence.removeOnPresenceChange)(this.onPresenceChangeCallback);
      }
    }
    get activeChannel() {
      return this._activeChannel;
    }
    set activeChannel(channel) {
      if (!channel) {
        this._activeMessage = null;
      }
      if (this._activeChannel) {
        this._activeChannel.activeThread = null;
      }
      this._activeChannel = channel;
    }
    get userCanDirectMessage() {
      if (!this.currentUser) {
        return false;
      }
      return this.currentUser.staff || this.currentUser.can_direct_message;
    }
    static #_16 = (() => dt7948.n(this.prototype, "userCanDirectMessage", [(0, _object.computed)("currentUser.staff", "currentUser.groups.[]")]))();
    get userHasDirectMessages() {
      return this.chatChannelsManager.directMessageChannels?.length > 0;
    }
    static #_17 = (() => dt7948.n(this.prototype, "userHasDirectMessages", [(0, _object.computed)("chatChannelsManager.directMessageChannels")]))();
    get userCanAccessDirectMessages() {
      return this.userCanDirectMessage || this.userHasDirectMessages;
    }
    get userCanInteractWithChat() {
      return !this.activeChannel?.userSilenced;
    }
    static #_18 = (() => dt7948.n(this.prototype, "userCanInteractWithChat", [(0, _object.computed)("activeChannel.userSilenced")]))();
    get activeMessage() {
      return this._activeMessage;
    }
    set activeMessage(hash) {
      if (hash) {
        this._activeMessage = hash;
      } else {
        this._activeMessage = null;
      }
    }
    onPresenceChangeCallback(present) {
      if (present) {
        // NOTE: channels is more than a simple array, it also contains
        // tracking and membership data, see Chat::StructuredChannelSerializer
        this.chatApi.listCurrentUserChannels().then(channelsView => {
          this.chatSubscriptionsManager.stopChannelsSubscriptions();
          this.chatSubscriptionsManager.startChannelsSubscriptions(channelsView.meta.message_bus_last_ids);
          [...channelsView.public_channels, ...channelsView.direct_message_channels].forEach(channelObject => {
            this.chatChannelsManager.find(channelObject.id, {
              fetchIfNotFound: false
            }).then(channel => {
              if (!channel) {
                return;
              }
              // NOTE: We need to do something here for thread tracking
              // state as well on presence change, otherwise we will be back in
              // the same place as the channels were.
              //
              // At some point it would likely be better to just fetch an
              // endpoint that gives you all channel tracking state and the
              // thread tracking state for the current channel.

              // ensures we have the latest message bus ids
              channel.meta.message_bus_last_ids = channelObject.meta.message_bus_last_ids;
              const state = channelsView.tracking.channel_tracking[channel.id];
              channel.tracking.unreadCount = state.unread_count;
              channel.tracking.mentionCount = state.mention_count;
              channel.tracking.watchedThreadsUnreadCount = state.watched_threads_unread_count;
              channel.currentUserMembership = channelObject.current_user_membership;
              this.chatSubscriptionsManager.startChannelSubscription(channel);
            });
          });
        });
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "onPresenceChangeCallback", [_decorators.bind]))();
    markNetworkAsUnreliable() {
      (0, _runloop.cancel)(this._networkCheckHandler);
      this.set("isNetworkUnreliable", true);
      this._networkCheckHandler = (0, _later.default)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.markNetworkAsReliable();
      }, 30000);
    }
    markNetworkAsReliable() {
      (0, _runloop.cancel)(this._networkCheckHandler);
      this.set("isNetworkUnreliable", false);
    }
    async loadChannels() {
      // We want to be able to call this method multiple times, but only
      // actually load the channels once. This is because we might call
      // this method before the chat is fully initialized, and we don't
      // want to load the channels multiple times in that case.
      try {
        if (this.chatStateManager.hasPreloadedChannels) {
          return;
        }
        if (this.loadingChannels) {
          return this.loadingChannels;
        }
        this.loadingChannels = new Promise(resolve => {
          this.chatApi.listCurrentUserChannels().then(result => {
            this.setupWithPreloadedChannels(result);
            this.chatStateManager.hasPreloadedChannels = true;
            resolve();
          });
        });
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    setupWithPreloadedChannels(channelsView) {
      this.chatSubscriptionsManager.startChannelsSubscriptions(channelsView.meta.message_bus_last_ids);
      this.presenceChannel.subscribe(channelsView.global_presence_channel_state);
      [...channelsView.public_channels, ...channelsView.direct_message_channels].forEach(channelObject => {
        const storedChannel = this.chatChannelsManager.store(channelObject);
        const storedDrafts = (this.currentUser?.chat_drafts || []).filter(draft => draft.channel_id === storedChannel.id);
        storedDrafts.forEach(storedDraft => {
          this.chatDraftsManager.add(_chatMessage.default.createDraftMessage(storedChannel, Object.assign({
            user: this.currentUser
          }, JSON.parse(storedDraft.data))), storedDraft.channel_id, storedDraft.thread_id);
        });
        if (channelsView.unread_thread_overview?.[storedChannel.id]) {
          storedChannel.threadsManager.unreadThreadOverview = channelsView.unread_thread_overview[storedChannel.id];
        }
        return this.chatChannelsManager.follow(storedChannel);
      });
      this.chatTrackingStateManager.setupWithPreloadedState(channelsView.tracking);
    }
    updatePresence() {
      (0, _runloop.next)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        if (this.currentUser.user_option?.hide_presence) {
          return;
        }
        if (this.chatStateManager.isActive) {
          this.presenceChannel.enter({
            activeOptions: CHAT_ONLINE_OPTIONS
          });
        } else {
          this.presenceChannel.leave();
        }
      });
    }
    getDocumentTitleCount() {
      return this.chatNotificationManager.shouldCountChatInDocTitle() ? this.chatTrackingStateManager.allChannelUrgentCount : 0;
    }
    switchChannelUpOrDown(direction) {
      let unreadOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const {
        activeChannel
      } = this;
      if (!activeChannel) {
        return; // Chat isn't open. Return and do nothing!
      }
      let publicChannels, directChannels;
      if (unreadOnly) {
        publicChannels = this.chatChannelsManager.publicMessageChannelsWithActivity;
        directChannels = this.chatChannelsManager.directMessageChannelsWithActivity;
      } else {
        publicChannels = this.chatChannelsManager.publicMessageChannels;
        directChannels = this.chatChannelsManager.directMessageChannels;
      }
      let currentList, otherList;
      if (activeChannel.isDirectMessageChannel) {
        currentList = directChannels;
        otherList = publicChannels;
      } else {
        currentList = publicChannels;
        otherList = directChannels;
      }
      const directionUp = direction === "up";
      const currentChannelIndex = currentList.findIndex(c => c.id === activeChannel.id);
      let nextChannelInSameList = currentList[currentChannelIndex + (directionUp ? -1 : 1)];
      if (nextChannelInSameList) {
        // You're navigating in the same list of channels, just use index +- 1
        return this.router.transitionTo("chat.channel", ...nextChannelInSameList.routeModels);
      }

      // You need to go to the next list of channels, if it exists.
      const nextList = otherList.length ? otherList : currentList;
      const nextChannel = directionUp ? nextList[nextList.length - 1] : nextList[0];
      if (nextChannel.id !== activeChannel.id) {
        return this.router.transitionTo("chat.channel", ...nextChannel.routeModels);
      }
    }
    _fireOpenFloatAppEvent(channel) {
      let messageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      messageId ? this.router.transitionTo("chat.channel.near-message", ...channel.routeModels, messageId) : this.router.transitionTo("chat.channel", ...channel.routeModels);
    }
    async followChannel(channel) {
      return this.chatChannelsManager.follow(channel);
    }
    async unfollowChannel(channel) {
      return this.chatChannelsManager.unfollow(channel).then(() => {
        if (channel === this.activeChannel && channel.isDirectMessageChannel) {
          this.router.transitionTo("chat");
        }
      });
    }
    upsertDmChannelForUser(channel, user) {
      const usernames = (channel.chatable.users || []).mapBy("username").concat(user.username).uniq();
      return this.upsertDmChannel({
        usernames
      });
    }

    // @param {object} targets - The targets to create or fetch the direct message
    // channel for. The current user will automatically be included in the channel when it is created.
    // @param {array} [targets.usernames] - The usernames to include in the direct message channel.
    // @param {array} [targets.groups] - The groups to include in the direct message channel.
    // @param {object} opts - Optional values when fetching or creating the direct message channel.
    // @param {string|null} [opts.name] - Name for the direct message channel.
    // @param {boolean} [opts.upsert] - Should we attempt to fetch existing channel before creating a new one.
    createDmChannel(targets) {
      let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        name: null,
        upsert: false
      };
      return (0, _ajax.ajax)("/chat/api/direct-message-channels.json", {
        method: "POST",
        data: {
          target_usernames: targets.usernames?.uniq(),
          target_groups: targets.groups?.uniq(),
          upsert: opts.upsert,
          name: opts.name
        }
      }).then(response => {
        const channel = this.chatChannelsManager.store(response.channel);
        this.chatChannelsManager.follow(channel);
        return channel;
      }).catch(_ajaxError.popupAjaxError);
    }
    upsertDmChannel(targets) {
      let name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      return this.createDmChannel(targets, {
        name,
        upsert: true
      });
    }

    // @param {array} usernames - The usernames to fetch the direct message
    // channel for. The current user will automatically be included as a
    // participant to fetch the channel for.
    getDmChannelForUsernames(usernames) {
      return (0, _ajax.ajax)("/chat/direct_messages.json", {
        data: {
          usernames: usernames.uniq().join(",")
        }
      });
    }
    addToolbarButton() {
      (0, _deprecated.default)("Use the new chat API `api.registerChatComposerButton` instead of `chat.addToolbarButton`", {
        id: "discourse.chat.addToolbarButton"
      });
    }
    toggleDrawer() {
      this.chatStateManager.didToggleDrawer();
      this.appEvents.trigger("chat:toggle-expand", this.chatStateManager.isDrawerExpanded);
    }
    static #_20 = (() => dt7948.n(this.prototype, "toggleDrawer", [_object.action]))();
  }
  _exports.default = Chat;
});